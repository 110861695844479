
<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

export default {
  page: {
    title: "Purchased Pre-buy Voucher Plan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader,DatePicker,Multiselect },
  data() {
    return {
      
      tableData: [],
      voucherData:[],
      plansArr:[],
      voucherID:0,

      title: "Purchased Pre-buy Voucher Plan",
      items: [
        {
          text: "View",
        },
        {
          text: "Purchased Pre-buy Voucher Plan",
          active: true,
        },
      ],
        voucherTypeArr:[{'typeID':1,'name':'Cash Voucher'},
      {'typeID':2,'name':'Product Voucher'}],
      statusArr:[{'id':1,'status':'Available'},{'id':2,'status':'Redeemed'}],
      orderDetails:[],
      orderedItemArr:[],
      orderItemAddonArr:[],
      orderStatusArr:[],
      daterange:[],
      branchID:"",
      brandData:"",
      brandArr:[],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        {
          key: "Action",
          sortable: true,        
        },
        {
          key: "restaurant",          
          sortable: true,        
        },

        {
          key: "voucherName",          
          sortable: true,        
        },
        {
          key: "userName",
          label: "Customer Name",
          sortable: true,          
        },
        {
          key: "purchasedVoucher",        
          sortable: true,     
          tdClass: "align-right",     
        },
        {
          key: "freeVoucher",          
          sortable: true,
          tdClass: "align-right",          
        },
        {
          key: "singleVoucherPrice",          
          sortable: true,
          tdClass: "align-right",          
        },
        {
          key: "itemTotal",      
          sortable: true,
          tdClass: "align-right",        
        },
        {
          key: "discount",          
          sortable: true,
          tdClass: "align-right",          
        },
        {
          key: "GST",          
          sortable: true,    
          tdClass: "align-right",      
        },
        {
          key: "orderTotal",          
          sortable: true,  
          tdClass: "align-right",       
        },
        {
          key: "purchasedDate",          
          sortable: true,         
        },
         {
          key: "expiryDate",          
          sortable: true,         
        },
      ],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
   
    if(this.$storageData.login_type == 2 || this.$storageData.profile.empTypeID == 2 || this.$storageData.profile.empTypeID == 11){
        this.getRestaurantCountry();
    }else{
        this.getPurchasedVoucherPlans();
    }

    
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getPurchasedVoucherPlans(){
         this.axios
        .post(this.$loggedRole+"/getPurchasedVoucherPlans",{
            'restBranchID':(this.$storageData.login_type == 2 || this.$storageData.profile.empTypeID == 2 || this.$storageData.profile.empTypeID == 11) ? this.restBranchID : this.$storageData.profile.restBranchID,
            'dateFilter':(this.daterange) ? this.daterange : [],
            })
        .then((response) => {
          //Then injecting the result to datatable parameters.

           this.tableData = response.data.data;
           this.voucherData = response.data.cardVoucherData;
             this.plansArr = response.data.plansArr;
           //console.log((response));
          
        });
    },
applyFilter(){
    this.axios
        .post(this.$loggedRole+"/getPurchasedVoucherPlans",{
              'restBranchID':(this.$storageData.login_type == 2 || this.$storageData.profile.empTypeID == 2 || this.$storageData.profile.empTypeID == 11) ? this.restBranchID : this.$storageData.profile.restBranchID,
              'dateFilter':(this.daterange) ? this.daterange : [],
              'voucherID':(this.voucherID) ? this.voucherID.voucherID : 0,
    
        }).then((response) => {
          //Then injecting the result to datatable parameters.

           this.tableData = response.data.data;
           this.voucherData = response.data.cardVoucherData;
           this.plansArr = response.data.plansArr;
         
          
        });
    },


    clearFilter(){
       
        this.daterange = [];
        this.voucherID ="";
        this.getPurchasedVoucherPlans();
    },
   getRestaurantBrandsByCompanyID(){
         this.axios
        .post(this.$loggedRole+"/getRestaurantBrandsByCompanyID", {companyID:this.$storageData.profile.companyID}
        )
        .then((response) => {
                this.brandArr = response.data.data;
                this.brandData = (this.brandArr) ? this.brandArr[0] : "";
                this.readRestaurantBranchData();
                
        }); 
    },
    getRestaurantCountry(){
        this.axios
        .post(this.$loggedRole+"/getRestaurantCountry", {restID:this.$storageData.profile.restID}
        )
        .then((response) => {
                this.countryArr = response.data.data;
                this.country = (this.countryArr) ? this.countryArr[0] : "";
                this.getRestaurantCities();
                
        });
    },
    getRestaurantCities(){
    
         this.axios
        .post(this.$loggedRole+"/getRestaurantCities", {restID:(this.$storageData.login_type==1) ? this.restaurantID.restID : this.$storageData.profile.restID,
           country: (this.country) ? this.country.country : ""}
        )
        .then((response) => {
                this.cityArr = response.data.data;
                this.city = (this.cityArr) ? this.cityArr[0] : "";
                if(this.$storageData.profile.empTypeID==11){
                    this.getRestaurantBrandsByCompanyID();
                }else{
                     this.readRestaurantBranchData();
                }
                
        });
    },

    readRestaurantBranchData(){
         this.axios
        .post(this.$loggedRole+"/getRestaurantBranchByRestID", {restID:(this.brandData!="") ? this.brandData.restID : this.$storageData.profile.restID,
          'restBranchID':0,
          'empTypeID':this.$storageData.profile.empTypeID,
          city: (this.city) ? this.city.city : ""}
        )
        .then((response) => {
         this.branches = response.data.data;
         this.branchID = (this.branches) ? this.branches[0] :"";
         this.restBranchID = (this.branchID) ? this.branchID.restBranchID: "";
          this.getPurchasedVoucherPlans();
          
        });
    },





  },
  middleware: "authentication",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
   
       <!-- Cities List-->
        <div class="col-md-2" style="position: absolute;right: 39%;top: 75px;"
        v-if="this.$storageData.login_type == 2 || this.$storageData.profile.empTypeID == 2 || this.$storageData.profile.empTypeID == 11">

                     <label>Select Country </label>
                        <multiselect v-model="country" :options="countryArr" track-by="country" label="country"
                          @input="getRestaurantCities();" :show-labels="false"></multiselect>
         </div>
        <div class="col-md-2" style="position: absolute; right: 24%; top: 75px; width: 15%;" 
        v-if="this.$storageData.login_type == 2 || this.$storageData.profile.empTypeID == 2 || this.$storageData.profile.empTypeID == 11">

                     <label>Select City </label>
                        <multiselect v-model="city" :options="cityArr" track-by="city" label="city"
                          @input="readRestaurantBranchData();" :show-labels="false"></multiselect>
         </div>
        <!--END Cities List-->
        <div class="row mt-4">
         <div class="col-md-3 mb-3" v-if="this.$storageData.profile.empTypeID==11">

                     <label>Select Restaurant Brand</label>
                        <multiselect v-model="brandData" :options="brandArr" track-by="restID" label="name"
                          @input="readRestaurantBranchData();" :show-labels="false"></multiselect>
        </div>

         <div class="col-md-2" 
         v-if="this.$storageData.login_type == 2 || this.$storageData.profile.empTypeID == 2 || this.$storageData.profile.empTypeID == 11">

                     <label>Select Restaurant Branch </label>
                        <multiselect v-model="branchID" :options="branches" track-by="restBranchID" label="branchName"
                          @input="onchangeBranch();" :show-labels="false"></multiselect>
         </div>
         
        <div class="col-sm-6 col-md-3 ">
                        <label class="form-label" for="formrow-status-input">Select Voucher Plan</label>
                            <multiselect v-model="voucherID" :options="plansArr" :show-labels="false" track-by="voucherID" label="voucherTitle"
                               @input="applyFilter();" > 
                        </multiselect>
        </div>
         <div class="col-sm-6 col-md-3" >
          <label>Date Range* </label>
            <date-picker v-model="daterange" format="DD MMM Y" value-type="format" range append-to-body lang="en" confirm @input="applyFilter();"></date-picker>
           
          </div>
           <div class="col-sm-6 col-md-1 mt-4" >
              <button v-if="daterange.length>0 || voucherID" class="btn btn-themeBrown" v-on:click="clearFilter()"> Clear </button>
            </div>
        </div>
    <div class="row" style="margin-top:30px;">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
                <div class="row mt-4">
               <div class="col-sm-12 col-md-4" style="margin-bottom:15px;" v-if="this.$storageData.login_type == 1">
                    <div role="group" class="btn-group">
                      <button type="button" class="btn btn-themeBrown">Excel</button> 
                       <!-- <button type="button" class="btn btn-themeBrown">Column Visibility</button> -->
                    </div>
               </div>
              <div class="col-sm-12 col-md-4">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show &nbsp;&nbsp;
                    <b-form-select style="margin-left:5px; margin-right:5px"
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;&nbsp;&nbsp; entries
                  </label>
                </div>
              </div>
             
              <!-- Search -->
              <div class="col-sm-12 col-md-4 row">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-end"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ms-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
 
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                striped hover
                outlined
                bordered
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                
                 fixed-header
              >
              
               <!-- <template #cell(Action)>
                  <i class="uil uil-edit-alt" title="Edit" style="font-size: 19px;"></i>
                  <i class="mdi mdi-delete" title="Delete" style="font-size: 19px;"></i>
                </template> -->
              
               <template v-slot:cell(orderTotal)="data">
                 <span v-if="data.item.orderTotal">₹{{data.item.orderTotal}}</span>
                </template>

                <template v-slot:cell(singleVoucherPrice)="data">
                 <span v-if="data.item.singleVoucherPrice">₹{{data.item.singleVoucherPrice}}</span>
                </template>

                <template v-slot:cell(itemTotal)="data">
                 <span v-if="data.item.itemTotal">₹{{data.item.itemTotal}}</span>
                </template>

                <template v-slot:cell(discount)="data">
                 <span v-if="data.item.discount">₹{{data.item.discount}}</span>
                </template>

                 <template v-slot:cell(Action)="data">
                   <router-link :to="{ name: 'purchasedVouchersTable', params: { id: data.item.userVoucherID , type:'voucherPlan' } }">
                     <button class="btn btn-themeYellow" style="padding: 4px 8px;"> View Voucher </button>
                   </router-link>
                </template>
                
   
              </b-table>
                
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </Layout>
</template>

